/* Avatar styling */
.avatar__section {
    position: relative;
    overflow: hidden;
  }
  
  .avatar__figure {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform-style: preserve-3d;
    transition: transform 1.6s ease-out;
    aspect-ratio: 3 / 4;
  }
  
  /* .avatar__figure:hover {
    transform: scale(1.12);
  } */
  
  .avatar__figure > picture {
    position: absolute;
    transition: transform 0.8s ease;
  }

  .avatar__figure > .avatar__shadow{
    filter: blur(40px);
    animation: blurAnimation 2s ease-in-out infinite;
    opacity: 0.5;
  }
  
  @keyframes blurAnimation {
    0% {
      filter: blur(40px);
    }
    50% {
      filter: blur(80px); /* Maximum blur */
    }
    100% {
      filter: blur(40px);
    }
  }