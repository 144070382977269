html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar{
  height: 10px;
  width: 10px;
}
::-webkit-scrollbar-thumb{
  height: 10px;
  width: 10px;
  border-radius: 2px;
  background-color: #FF4141;
}
::-webkit-scrollbar-track{
  background-color: #282c34;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: MontserratM;
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: MontserratR;
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: MontserratSB;
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}

.maindog {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 90%;
  height: auto;
}

.orangebox {
  display: flex;
  max-width: 100%;
  height: auto;
}

.maintext {
  color: #F9860B;
}

.maintext::before {
  content: "INU";
  position: absolute;
  right: 5px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;

}

.FeatureDog {
  max-width: 100%;
  height: auto;
}

.supstyle {
  font-size: 95px;

  @media(max-width:600px) {
    font-size: 40px;
  }
}


body {
  background-color: #000;
}

@keyframes Blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}